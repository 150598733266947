/* eslint-disable max-len */
// Auto generated file. Do not edit.

export const errorPage: Record<string, TErrorPage> = {
  'en-AU': {
    errorPage: {
      error: 'error',
      notFound: {
        title: 'Page not found',
        mainMessage: 'It seems the page you were looking for has been lost at sea',
        additionalMessage: 'If you reached this error page by typing an address into your web browser, please verify the address again and try reloading the page. Otherwise, please return to our Home Page.',
        button: 'Back to homepage'
      },
      serverError: {
        title: 'Oops something went wrong',
        mainMessage: 'Try reloading the page, otherwise please return to our Home Page.',
        error: 'Server error',
        button: 'Back to home page'
      },
      technicalDifficulties: {
        message: 'Sorry, we\'re experiencing some technical difficulties.'
      },
      tryAgainLater: 'Please try again later.',
      myAccount: {
        retrievingBookingMessage: 'Sorry, we ran into an issue when retrieving your booking.',
        button: 'Back to My Account'
      }
    }
  },
  'en-GB': {
    errorPage: {
      error: 'error',
      notFound: {
        title: 'Page not found',
        mainMessage: 'It seems the page you were looking for has been lost at sea',
        additionalMessage: 'If you reached this error page by typing an address into your web browser, please verify the address again and try reloading the page. Otherwise, please return to our Home Page.',
        button: 'Back to homepage'
      },
      serverError: {
        title: 'Oops something went wrong',
        mainMessage: 'Try reloading the page, otherwise please return to our Home Page.',
        error: 'Server error',
        button: 'Back to home page'
      },
      technicalDifficulties: {
        message: 'Sorry, we\'re experiencing some technical difficulties.'
      },
      tryAgainLater: 'Please try again later.',
      myAccount: {
        retrievingBookingMessage: 'Sorry, we ran into an issue when retrieving your booking.',
        button: 'Back to My Account'
      }
    }
  },
  'en-US': {
    errorPage: {
      error: 'error',
      notFound: {
        title: 'Page not found',
        mainMessage: 'It seems the page you were looking for has been lost at sea',
        additionalMessage: 'If you reached this error page by typing an address into your web browser, please verify the address again and try reloading the page. Otherwise, please return to our Home Page.',
        button: 'Back to homepage'
      },
      serverError: {
        title: 'Oops something went wrong',
        mainMessage: 'Try reloading the page, otherwise please return to our Home Page.',
        error: 'Server error',
        button: 'Back to home page'
      },
      technicalDifficulties: {
        message: 'Sorry, we\'re experiencing some technical difficulties.'
      },
      tryAgainLater: 'Please try again later.',
      myAccount: {
        retrievingBookingMessage: 'Sorry, we ran into an issue when retrieving your booking.',
        button: 'Back to My Account'
      }
    }
  },
  'de-DE': {
    errorPage: {
      error: 'Fehler',
      notFound: {
        title: 'Seite nicht gefunden',
        mainMessage: 'Es scheint, dass die von Ihnen gesuchte Seite auf See verschollen ist',
        additionalMessage: 'Wenn Sie diese Fehlerseite durch Eingabe einer Adresse in Ihren Webbrowser erreicht haben, überprüfen Sie bitte die Adresse erneut und versuchen Sie, die Seite neu zu laden. Andernfalls kehren Sie bitte zu unserer Startseite zurück.',
        button: 'Zurück zur Startseite'
      },
      serverError: {
        title: 'Hoppla! Leider ist ein Fehler aufgetreten.',
        mainMessage: 'Versuchen Sie, die Seite neu zu laden; andernfalls kehren Sie bitte zu unserer Startseite zurück.',
        error: 'Server-Fehler',
        button: 'Zurück zur Startseite'
      },
      technicalDifficulties: {
        message: 'Es tut uns leid, wir haben einige technische Schwierigkeiten.'
      },
      tryAgainLater: 'Bitte versuchen Sie es später noch einmal',
      myAccount: {
        retrievingBookingMessage: 'Leider ist beim Abrufen Ihrer Buchung ein Problem aufgetreten.',
        button: 'Zurück zu Mein Konto'
      }
    }
  },
  'gsw-CH': {
    errorPage: {
      error: 'Fehler',
      notFound: {
        title: 'Seite nicht gefunden',
        mainMessage: 'Es scheint, dass die von Ihnen gesuchte Seite auf See verschollen ist',
        additionalMessage: 'Wenn Sie diese Fehlerseite durch Eingabe einer Adresse in Ihren Webbrowser erreicht haben, überprüfen Sie bitte die Adresse erneut und versuchen Sie, die Seite neu zu laden. Andernfalls kehren Sie bitte zu unserer Startseite zurück.',
        button: 'Zurück zur Startseite'
      },
      serverError: {
        title: 'Hoppla! Leider ist ein Fehler aufgetreten.',
        mainMessage: 'Versuchen Sie, die Seite neu zu laden; andernfalls kehren Sie bitte zu unserer Startseite zurück.',
        error: 'Server-Fehler',
        button: 'Zurück zur Startseite'
      },
      technicalDifficulties: {
        message: 'Es tut uns leid, wir haben einige technische Schwierigkeiten.'
      },
      tryAgainLater: 'Bitte versuchen Sie es später noch einmal',
      myAccount: {
        retrievingBookingMessage: 'Leider ist beim Abrufen Ihrer Buchung ein Problem aufgetreten.',
        button: 'Zurück zu Mein Konto'
      }
    }
  },
  'fr-FR': {
    errorPage: {
      error: 'erreur',
      notFound: {
        title: 'Page introuvable',
        mainMessage: 'Il semble que la page que vous recherchez ait disparu en mer',
        additionalMessage: 'Si vous avez atterri sur cette page d’erreur en tapant une adresse dans votre navigateur, merci de revérifier l’adresse et d’essayer de recharger la page. Dans le cas contraire, veuillez retourner sur notre page d’accueil.',
        button: 'Retour à la page d’accueil'
      },
      serverError: {
        title: 'Oups, une erreur est survenue',
        mainMessage: 'Essayez de recharger la page ou retournez à notre page d’accueil.',
        error: 'Erreur de serveur',
        button: 'Retour à la page d’accueil'
      },
      technicalDifficulties: {
        message: ' Désolé, nous rencontrons quelques difficultés techniques.'
      },
      tryAgainLater: 'Veuillez réessayer plus tard.',
      myAccount: {
        retrievingBookingMessage: 'Désolé, nous avons rencontré un problème lors de la récupération de votre réservation.',
        button: 'Retour sur mon compte'
      }
    }
  },
  'da-DK': {
    errorPage: {
      error: 'fejl',
      notFound: {
        title: 'Siden blev ikke fundet',
        mainMessage: 'Det lader til, at den side, du leder efter, er gået tabt på havet',
        additionalMessage: 'Hvis du kom til denne fejlside ved at indtaste en adresse i din webbrowser, skal du kontrollere adressen og prøve at indlæse siden igen. Ellers gå tilbage til vores startside.',
        button: 'Tilbage til startsiden'
      },
      serverError: {
        title: 'Ups, der gik noget galt',
        mainMessage: 'Prøv at genindlæse siden, ellers kan du gå tilbage til vores startside.',
        error: 'Serverfejl',
        button: 'Tilbage til startsiden'
      },
      technicalDifficulties: {
        message: 'Beklager, vi oplever nogle tekniske problemer.'
      },
      tryAgainLater: 'Prøv venligst igen senere.',
      myAccount: {
        retrievingBookingMessage: 'Beklager, vi stødte på et problem, da vi hentede din booking.',
        button: 'Tilbage til Min Konto'
      }
    }
  },
  'sv-SE': {
    errorPage: {
      error: 'fel',
      notFound: {
        title: 'Sidan hittades inte',
        mainMessage: 'Det ser ut som sidan du letade efter har försvunnit till sjöss',
        additionalMessage: 'Om du hamnade på den här felsidan genom att skriva in en adress i din webbläsare ska du kontrollera adressen igen och försöka läsa in sidan på nytt. Annars kan du gå tillbaka till hemsidan.',
        button: 'Tillbaka till hemsidan'
      },
      serverError: {
        title: 'Hoppsan! Något gick fel.',
        mainMessage: 'Försök läsa in sidan på nytt. Om det inte fungerar går du tillbaka till hemsidan.',
        error: 'Serverfel',
        button: 'Tillbaka till hemsidan'
      },
      technicalDifficulties: {
        message: 'Ursäkta, vi upplever några tekniska problem.'
      },
      tryAgainLater: 'Vänligen försök igen senare.',
      myAccount: {
        retrievingBookingMessage: 'Ursäkta, vi stötte på ett problem när vi hämtade din bokning.',
        button: 'Tillbaka till Mitt Konto'
      }
    }
  },
  'nb-NO': {
    errorPage: {
      error: 'feil',
      notFound: {
        title: 'Fant ikke siden',
        mainMessage: 'Det ser ut til at denne siden har blåst på havet',
        additionalMessage: 'Hvis du kom til denne siden ved å skrive inn en adresse i nettleseren din, dobbeltsjekk adressen og prøv å laste inn siden på nytt. Fungerer det ikke? Gå tilbake til forsiden.',
        button: 'Tilbake til forsiden'
      },
      serverError: {
        title: 'Oi sann! Noe gikk galt',
        mainMessage: 'Prøv å laste inn siden på nytt, eller gå tilbake til forsiden.',
        error: 'Serverfeil',
        button: 'Tilbake til forsiden'
      },
      technicalDifficulties: {
        message: 'Beklager, vi opplever noen tekniske problemer.'
      },
      tryAgainLater: 'Vennligst prøv igjen senere.',
      myAccount: {
        retrievingBookingMessage: 'Beklager, vi støtte på et problem da vi hentet din bestilling.',
        button: 'Tilbake til Min Konto'
      }
    }
  },
  'en': {
    errorPage: {
      error: 'error',
      notFound: {
        title: 'Page not found',
        mainMessage: 'It seems the page you were looking for has been lost at sea',
        additionalMessage: 'If you reached this error page by typing an address into your web browser, please verify the address again and try reloading the page. Otherwise, please return to our Home Page.',
        button: 'Back to homepage'
      },
      serverError: {
        title: 'Oops something went wrong',
        mainMessage: 'Try reloading the page, otherwise please return to our Home Page.',
        error: 'Server error',
        button: 'Back to home page'
      },
      technicalDifficulties: {
        message: 'Sorry, we\'re experiencing some technical difficulties.'
      },
      tryAgainLater: 'Please try again later.',
      myAccount: {
        retrievingBookingMessage: 'Sorry, we ran into an issue when retrieving your booking.',
        button: 'Back to My Account'
      }
    }
  }
}

export type TErrorPage = {
  errorPage: {
    error: string;
    notFound: {
      title: string;
      mainMessage: string;
      additionalMessage: string;
      button: string;
    };
    serverError: {
      title: string;
      mainMessage: string;
      error: string;
      button: string;
    };
    technicalDifficulties: {
      message: string;
    };
    tryAgainLater: string;
    myAccount: {
      retrievingBookingMessage: string;
      button: string;
    };
  };
}
