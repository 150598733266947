import { Layout } from '@organisms';
import { useTranslate, useLocale } from '@hooks';
import { metaDescription } from '@microcopies/sets/metaDescription';
import { errorPage } from '@microcopies';
import ErrorPageContent from '@src/components/ErrorPageContent';

export default function Custom404() {
  const locale = useLocale();
  const translateMeta = useTranslate(
    metaDescription,
    (x) => x.metaDescription.error
  );

  const translate = useTranslate(errorPage, (x) => x.errorPage);

  return (
    <Layout
      title={translateMeta((x) => x.title)}
      metaDescription={translateMeta((x) => x.description)}
    >
      <ErrorPageContent
        error={`404 ${translate((x) => x.error)}`}
        title={translate((x) => x.notFound.mainMessage)}
        buttonText={translate((x) => x.notFound.button)}
        buttonHref={`/${locale}/`}
      />
    </Layout>
  );
}

export const getStaticProps = async () => ({ props: {} });
