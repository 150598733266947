import clsx from 'clsx';
import { Button } from '@hurtigruten/design-system-components';

import { Fluid } from '@components';

const ErrorPageContent = ({
  error,
  title,
  subtitle,
  helperText,
  buttonText,
  buttonHref
}: {
  error?: string;
  title: string;
  subtitle?: string;
  helperText?: string;
  buttonText?: string;
  buttonHref?: string;
}) => (
  <div className="w-full h-screen bg-[url('/img/errorPage.jpg')] bg-cover  bg-offRight ">
    <div className="flex flex-col justify-center w-full h-full text-white bg-black mobile:px-6 bg-opacity-20 ">
      <Fluid className="w-full my-10 tablet:my-20">
        <div className="mobile:w-full desktop:w-[65%] -translate-y-12">
          {error && (
            <p className="mb-8 text-xl capitalize tablet:text-4xl ">{error} </p>
          )}

          <h1
            className={clsx(
              ' text-3xl tablet:text-6xl font-bold leading-normal tablet:leading-tight text-white font-jost mb-6',
              {
                'mb-12': error?.includes('404')
              }
            )}
          >
            {title}
          </h1>

          {subtitle && (
            <h2 className="mb-8 text-lg text-white tablet:text-4xl font-jost">
              {subtitle}
            </h2>
          )}
          {helperText && (
            <p className="text-base font-medium tablet:text-lg mb-14">
              {helperText}{' '}
            </p>
          )}

          {buttonText && (
            <Button
              appearance="secondary"
              icon="arrow-right"
              text={buttonText}
              fillMobileWidth={false}
              href={buttonHref}
            />
          )}
        </div>
      </Fluid>
    </div>
  </div>
);

export default ErrorPageContent;
